import { Provider } from './contact-model'
import { PropertyDetails } from '../../transports/property-details-page-transport'
import { RoutingFunction, TranslationFunction } from '../../common/types'
import { buildPath } from '../../routes/build-route'
import config from '../../common/config'

export interface FormattedBookingTypeData {
    type: string | null
    route: string | null
    requestContextUrl?: string | null
    title: string | null
    dynamicQueryParams?: string | null
    tooltip: string | null
    subType?: FormattedBookingTypeData | null
    noun: string | null
    icon: string | null
    shortTitle: string | null
    requestContext?: string | null
}

export enum BookingTypeEnum {
    INQUIRY = 1,
    DIRECT_BOOKING = 2,
    BINDING_INQUIRY = 3,
    BOOKING_AND_ENQUIRY = 4,
}

export enum BookingTypeStringsEnum {
    INQUIRY = 'inquiry',
    DIRECT_BOOKING = 'direct_booking',
    BINDING_INQUIRY = 'binding_inquiry',
    REDIRECT = 'redirect',
}

export class BookingType {
    private __: TranslationFunction
    private r: RoutingFunction

    constructor(translationFunction: TranslationFunction, r: RoutingFunction) {
        this.__ = translationFunction
        this.r = r
    }

    public format(
        bookingTypeId: PropertyDetails['bookingType'],
        tfwObjectId: PropertyDetails['tfwObjectId'],
        providerId: PropertyDetails['providerId'],
        externalObjectId: string,
        canReceiveEmail: boolean,
        isTraumPayEnabled: boolean,
    ): FormattedBookingTypeData {
        providerId = Number(providerId)
        const defaultFormat = this.getDefaultFormat()
        if (providerId === Provider.PROVIDER_WILDEAST) {
            if (canReceiveEmail) {
                defaultFormat.subType = this.formatMailOnly(tfwObjectId)
            }
            return { ...defaultFormat, ...this.formatGeneralDirectBookingType(tfwObjectId) }
        } else if (bookingTypeId === BookingTypeEnum.BOOKING_AND_ENQUIRY) {
            defaultFormat.subType = this.formatMailOnly(tfwObjectId)
            return { ...defaultFormat, ...this.formatGeneralDirectBookingType(tfwObjectId) }
        } else if (
            providerId === Provider.PROVIDER_BELVILLA ||
            providerId === Provider.PROVIDER_DANCENTER ||
            providerId === Provider.PROVIDER_TUI
        ) {
            if (bookingTypeId === BookingTypeEnum.INQUIRY) {
                return this.formatMail(tfwObjectId, defaultFormat)
            }

            return {
                ...defaultFormat,
                ...this.formatBelvilla(providerId, externalObjectId, tfwObjectId, isTraumPayEnabled),
            }
        } else if (this.getProvidersUsingRedirectFlow().includes(providerId!)) {
            defaultFormat.subType = this.formatMailOnly(tfwObjectId)
            defaultFormat.subType!.route = `${config.htgPublicUrl}rental/${externalObjectId}?campaign=compare/all/ha/traumFerienwohnungen/${externalObjectId}`
            return {
                ...defaultFormat,
                ...this.formatHTG(externalObjectId),
            }
        } else if (
            bookingTypeId === BookingTypeEnum.DIRECT_BOOKING &&
            this.getProvidersUsingGeneralDirectFormat().includes(providerId!)
        ) {
            return {
                ...defaultFormat,
                ...this.formatGeneralDirectBookingType(tfwObjectId, isTraumPayEnabled),
            }
        } else if (bookingTypeId === BookingTypeEnum.BINDING_INQUIRY) {
            if (canReceiveEmail) {
                defaultFormat.subType = this.formatMailOnly(tfwObjectId)
            }
            return {
                ...defaultFormat,
                ...this.formatBindingInquiry(providerId, externalObjectId, tfwObjectId),
            }
        } else if (canReceiveEmail) {
            return this.formatMail(tfwObjectId, defaultFormat)
        }

        return defaultFormat
    }

    private formatMail(
        tfwObjectId: PropertyDetails['tfwObjectId'],
        defaultFormat: FormattedBookingTypeData,
    ): FormattedBookingTypeData {
        defaultFormat.subType = this.formatMailOnly(tfwObjectId)
        return { ...defaultFormat, ...this.formatMailOnly(tfwObjectId) }
    }

    private formatMailOnly(tfwObjectId: PropertyDetails['tfwObjectId']): FormattedBookingTypeData {
        return {
            type: 'inquiry',
            route: buildPath(this.r('inquiry'), {
                routeObjectId: tfwObjectId,
            }),
            title: this.__('bookingType.inquiry.title'),
            shortTitle: this.__('bookingType.inquiry.shortTitle'),
            dynamicQueryParams: JSON.stringify({
                date_from: 'arrival',
                date_to: 'departure',
                persons: 'adults',
                children: 'childrenAges',
                arrival: 'arrival',
                departure: 'departure',
                adults: 'adults',
                filter: 'filter',
                tb: 'tb',
                from_page: 'from_page',
            }),
            noun: this.__('bookingType.inquiry.noun'),
            tooltip: this.__('bookingType.inquiry.tooltip'),
            icon: 'envelope-o',
        }
    }

    private formatGeneralDirectBookingType(
        tfwObjectId: PropertyDetails['tfwObjectId'],
        isTraumPayEnabled = false,
    ): FormattedBookingTypeData {
        return {
            type: 'direct_booking',
            route: buildPath(this.r('directBooking'), {
                routeObjectId: tfwObjectId,
            }),
            requestContextUrl: buildPath(this.r('directBookingNewUser'), {
                routeObjectId: tfwObjectId,
            }),
            requestContext: 'booking',
            title: isTraumPayEnabled
                ? this.__('bookingType.booking.traumPaymentsTitle')
                : this.__('bookingType.booking.title'),
            shortTitle: isTraumPayEnabled
                ? this.__('bookingType.booking.traumPaymentsShortTitle')
                : this.__('bookingType.booking.shortTitle'),
            dynamicQueryParams: JSON.stringify({
                date_from: 'arrival',
                date_to: 'departure',
                persons: 'adults',
                children: 'childrenAges',
                arrival: 'arrival',
                departure: 'departure',
                adults: 'adults',
            }),
            noun: this.__('bookingType.booking.noun'),
            tooltip: this.__('bookingType.booking.tooltip'),
            icon: 'rocket',
        }
    }

    private formatBindingInquiry(
        providerId: PropertyDetails['providerId'],
        externalObjectId: PropertyDetails['externalObjectId'],
        tfwObjectId: PropertyDetails['tfwObjectId'],
    ): FormattedBookingTypeData {
        return {
            type: 'binding_inquiry',
            route: buildPath(this.r('booking'), {
                routeObjectId: this.buildRouteObjectId(providerId!, externalObjectId!, tfwObjectId!),
            }),
            title: this.__('bookingType.bindingInquiry.title'),
            shortTitle: this.__('bookingType.bindingInquiry.shortTitle'),
            dynamicQueryParams: JSON.stringify({
                arrival: 'arrival',
                departure: 'departure',
                adults: 'adults',
                children: 'childrenAges',
                tb: 'tb',
                from_page: 'from_page',
            }),
            noun: this.__('bookingType.bindingInquiry.noun'),
            tooltip: this.__('bookingType.bindingInquiry.tooltip'),
            icon: 'clock-o',
        }
    }

    private formatBelvilla(
        providerId: PropertyDetails['providerId'],
        externalObjectId: PropertyDetails['externalObjectId'],
        tfwObjectId: PropertyDetails['tfwObjectId'],
        isTraumPayEnabled: boolean,
    ): FormattedBookingTypeData {
        return {
            type: 'direct_booking',
            route: buildPath(this.r('directBooking'), {
                routeObjectId: this.buildRouteObjectId(providerId!, externalObjectId!, tfwObjectId!),
            }),
            title: isTraumPayEnabled
                ? this.__('bookingType.booking.traumPaymentsTitle')
                : this.__('bookingType.booking.title'),
            shortTitle: isTraumPayEnabled
                ? this.__('bookingType.booking.traumPaymentsShortTitle')
                : this.__('bookingType.booking.shortTitle'),
            dynamicQueryParams: JSON.stringify({
                arrival: 'arrival',
                departure: 'departure',
                adults: 'adults',
                children: 'childrenAges',
            }),
            noun: this.__('bookingType.booking.noun'),
            tooltip: this.__('bookingType.booking.tooltip'),
            icon: 'rocket',
        }
    }

    private formatHTG(externalObjectId: PropertyDetails['externalObjectId']): FormattedBookingTypeData {
        return {
            type: 'redirect',
            route: `${config.htgPublicUrl}rental/${externalObjectId}?campaign=compare/all/ha/traumFerienwohnungen/${externalObjectId}`,
            title: this.__('bookingType.bookingRedirect.title'),
            shortTitle: this.__('bookingType.bookingRedirect.shortTitle'),
            noun: this.__('bookingType.bookingRedirect.noun'),
            tooltip: this.__('bookingType.bookingRedirect.tooltip'),
            icon: 'rocket',
        }
    }

    private getDefaultFormat(): FormattedBookingTypeData {
        return {
            type: null,
            route: null,
            title: null,
            dynamicQueryParams: null,
            tooltip: null,
            subType: null,
            noun: null,
            icon: null,
            shortTitle: null,
            requestContextUrl: null,
        }
    }

    private getProvidersUsingGeneralDirectFormat(): number[] {
        return [
            Provider.PROVIDER_FERATEL,
            Provider.PROVIDER_SECRA,
            Provider.PROVIDER_NEXTPAX,
            Provider.PROVIDER_VLABS,
            Provider.PROVIDER_FEWOVERWALTER,
            Provider.PROVIDER_WILDEAST,
            Provider.PROVIDER_AVANTIO,
            Provider.PROVIDER_TFW,
            Provider.PROVIDER_BEERENT,
            Provider.PROVIDER_MAGELLANO,
            Provider.PROVIDER_SILKA,
            Provider.PROVIDER_MYRENT,
        ]
    }

    private getProvidersUsingRedirectFlow(): number[] {
        return [Provider.PROVIDER_HOMETOGO]
    }

    private buildRouteObjectId(
        providerId: PropertyDetails['providerId'],
        externalObjectId: PropertyDetails['externalObjectId'],
        tfwObjectId?: PropertyDetails['tfwObjectId'],
    ): string {
        if (tfwObjectId) {
            return tfwObjectId.toString()
        }

        return `${providerId}-${externalObjectId}`
    }
}
